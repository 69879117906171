import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MedicalInfo } from './MedicalInfo';
import { history } from '../../_helpers';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import { medicalInfoAction } from '../../actions/medicalInfo.action';
import { Container } from 'react-bootstrap';


class SicknoteMedicalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formType: 'sick-note' // Default form type, also has school-work-form
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    
    componentDidMount() {
        const { dispatch } = this.props;
        
        // Get the form type from URL query parameter
        const urlParams = new URLSearchParams(window.location.search);
        const formType = urlParams.get('formType');
        
        // console.log('URL Params:', urlParams.toString());
        // console.log('Form Type from URL:', formType);
        
        if (formType) {
            this.setState({ formType });
            // console.log('Setting formType to:', formType);
        }

        dispatch(medicalInfoAction.getByPerson());
    }

    handleEdit() {
        const { formType } = this.state;
        history.push(`/medicalInfo/edit?formType=${formType}`);
    }

    handleConfirm() {
        // Read formType from state
        const { formType } = this.state;
        
        // console.log('Confirming with formType:', formType);
        
        if (formType === 'school-work-form') {
            // console.log('Redirecting to school-work-form');
            // Change this line to use the actual school-work-form path
            history.push('/school-work-form');
        } else {
            // console.log('Redirecting to default sick-note');
            history.push('/sick-note');
        }
    }

    render() {
        // Get formType from state
        const { formType } = this.state;
        
        // Determine title and subtitle based on form type
        let pageTitle = "Edit Medical Info";
        let pageSubtitle = "Medical Info";
        let btnConfirmText = "Confirm";
        
        if (formType === 'school-work-form') {
            pageTitle = "Request a Form - Medical Info";
            pageSubtitle = "Request Form";
        } else {
            pageTitle = "Sick Note - Medical Info";
            pageSubtitle = "Sick Note";
        }

        return (
            <>
            <CommonPageHeader title={pageTitle} subtitle={pageSubtitle} />

            <Container>
            <div className="sick-note-medical-info-container">
                <h1 className="h5 mb-3 font-weight-normal">First, please confirm your personal medical information:</h1>
                <hr />
                <MedicalInfo />
                <hr className="mb-4" />
                <div className="mb-3 d-flex justify-content-between">
                    <button className="primary_btn bg-secondary mr-3" onClick={this.handleEdit}>Add/Edit</button>
                    {/* {!this.props.noMedicalInfo && <button  */}
                    {/* TODO: currently show button regardless becasue of cors, need to fix this next */}
                    {  <button 
                        className="primary_btn" 
                        onClick={this.handleConfirm}
                        data-test-id={`${formType}-medical-info__confirm-button`}
                    >{btnConfirmText}</button>}
                </div>
            </div>
            </Container>
            </>
        );
    }
}


SicknoteMedicalInfo.propTypes = {
    dispatch: PropTypes.func.isRequired
};


function mapStateToProps(state) {
    const { noMedicalInfo } = state.medicalInfo;

    return {
        noMedicalInfo
    };
}

const temp = connect(mapStateToProps)(SicknoteMedicalInfo);

export { temp as SicknoteMedicalInfo };