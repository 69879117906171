//com.southvanmed/src/services/medicalinfo.service.js
import axios from 'axios';
import config from '../config';




export const medicalInfoService = {
    getByPerson,
    getById,
    save
};

function getByPerson() {

    const url = `${config.service}/services/medical-info`;
    let token = localStorage.getItem('accessToken');
    // console.log(token);
    axios.defaults.headers.common['x-access-token'] = token;
    return axios.get(url);
}

function getById(id) {
    const url = `${config.askmedApiHost}/medicalinfo/${id}`;
    axios.defaults.headers.common['x-access-token'] = config.askmedSecret;

    return axios.get(url);
}

function save(medicalInfoObj) {
    const url = `${config.askmedApiHost}/medicalinfo/`;
      // Debug log that will appear in the console
    //   console.log('Saving to URL:', url);
    //   console.log('Using config:', config.configId || 'Config ID not set');

    axios.defaults.headers.common['x-access-token'] = config.askmedSecret;

    return axios.post(url, medicalInfoObj);
}