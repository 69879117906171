// com.southvanmed/src/services/sicknote.service.js
import axios from 'axios';
import config from '../config';

export const sickNoteService = {
    getByPerson,
    getById,
    save,
    charge,
    saveSickNote,
    validateSickNote,
    validateSchoolWorkForm,
    saveSickNoteWithFiles,
    submitSchoolWorkFormToOscar,
    // Add new methods for the post-payment workflow
    getSchoolWorkForm,
    uploadSchoolWorkFormFiles,
    getSchoolWorkFormByToken,
    uploadSchoolWorkFormFilesByToken,
    submitSchoolWorkFormToOscarByToken
};

function getByPerson() {
    const url = `${config.service}/services/medical-info`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    return axios.get(url);
}

function getById(id) {
    const url = `${config.askmedApiHost}/medicalinfo/${id}`;
    axios.defaults.headers.common['x-access-token'] = config.askmedSecret;
    return axios.get(url);
}

function save(medicalInfoObj) {
    const url = `${config.askmedApiHost}/medicalinfo/`;
    axios.defaults.headers.common['x-access-token'] = config.askmedSecret;
    return axios.post(url, medicalInfoObj);
}

function charge(data) {
    const url = `${config.service}/sick-note/charge`;
    axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('accessToken');
    return axios.post(url, data);
}

function saveSickNote(sickNoteObj) {
    const url = `${config.service}/services/sicknote`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const body = {
        sickNoteObj
    };
    return axios.post(url, body);
}

function validateSickNote(sickNoteObj) {
    const url = `${config.service}/services/sicknote/validate`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const body = {
        sickNoteObj
    };
    return axios.post(url, body);
}

function validateSchoolWorkForm(formData) {
    const url = `${config.service}/services/schoolworkform/validate`;
    let token = localStorage.getItem('accessToken');
    axios.defaults.headers.common['x-access-token'] = token;
    const body = {
        formData
    };
    return axios.post(url, body);
}

// Updated function - now only saves form data without files
function saveSickNoteWithFiles(formData) {
    // Rename to be more accurate, but keep the function name for compatibility
    const url = `${config.service}/services2/sicknote/save_school_work_form`;
    let token = localStorage.getItem('accessToken');
    
    // Using x-auth-token to match the auth middleware
    const headers = {
        'Content-Type': 'application/json',
        'x-auth-token': token
    };
    
    // For the new workflow, we submit JSON instead of FormData
    // since we're not uploading files at this stage
    return axios.post(url, formData, { headers });
}

// Function to submit school work form to Oscar
function submitSchoolWorkFormToOscar(formId) {
    const url = `${config.service}/services2/sicknote/complete_oscar_submission`;
    let token = localStorage.getItem('accessToken');
    
    // Using x-auth-token to match the auth middleware
    const headers = {
        'Content-Type': 'application/json',
        'x-auth-token': token
    };
    
    return axios.post(url, { formId }, { headers });
}

// Method to get school work form by token
function getSchoolWorkFormByToken(token) {
    const url = `${config.service}/services2/sicknote/school_work_form_token/${token}`;
    let accessToken = localStorage.getItem('accessToken');
    
    const headers = {
      'x-auth-token': accessToken
    };
    
    return axios.get(url, { headers });
  }
  
  // Method to upload files using token
  function uploadSchoolWorkFormFilesByToken(token, formData) {
    const url = `${config.service}/services2/sicknote/upload_school_work_files_token/${token}`;
    let accessToken = localStorage.getItem('accessToken');
    
    const headers = {
      'Content-Type': 'multipart/form-data',
      'x-auth-token': accessToken
    };
    
    return axios.post(url, formData, { headers });
  }
  
  // Method to complete Oscar submission using token
  function submitSchoolWorkFormToOscarByToken(token) {
    const url = `${config.service}/services2/sicknote/complete_oscar_submission_token/${token}`;
    let accessToken = localStorage.getItem('accessToken');
    
    const headers = {
      'Content-Type': 'application/json',
      'x-auth-token': accessToken
    };
    
    return axios.post(url, {}, { headers });
  }

// New function to get school work form details
function getSchoolWorkForm(formId) {
    const url = `${config.service}/services2/sicknote/school_work_form/${formId}`;
    let token = localStorage.getItem('accessToken');
    
    // Using x-auth-token to match the auth middleware
    const headers = {
        'x-auth-token': token
    };
    
    return axios.get(url, { headers });
}

// New function to upload files after payment
function uploadSchoolWorkFormFiles(formData) {
    const url = `${config.service}/services2/sicknote/upload_school_work_files`;
    let token = localStorage.getItem('accessToken');
    
    // Using x-auth-token to match the auth middleware
    const headers = {
        'Content-Type': 'multipart/form-data',
        'x-auth-token': token
    };
    
    return axios.post(url, formData, { headers });
}