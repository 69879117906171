// com.southvanmed/src/MedicalInfo/MedicalInfo.jsx
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { medicalInfoAction } from '../../actions/medicalInfo.action';
import { formatDateTime } from '../../_helpers';


const styles = () => ({
    root: {
        marginTop: 20,
    }
});


class MedicalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen:false,
            selectId:null,
        };
        this.showPastMedicalInfoModel = this.showPastMedicalInfoModel.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    componentDidMount() {
        const { dispatch, status, medicalInfoObj} = this.props;
        // console.log('whats medicalInfoObj',medicalInfoObj)
        if (!medicalInfoObj) {
            console.log('no medicalinfoobj')
            dispatch(medicalInfoAction.getByPerson());
        }
        // if medicalInfoObj is changed during edit mode, get the latest one
        if ( status === 'changed') {
            dispatch(medicalInfoAction.getByPerson());
        }
    }

    showPastMedicalInfoModel(id) {
        this.setState({
            isModalOpen:true,
            selectId:id,
        })
    }

    handleModalClose(){
        this.setState({
            isModalOpen:false,
        })
    }

    renderPastMedicalInfos() {
        const { pastMedicalInfoObjs } = this.props;
        return (
            <div className="medicalinfo-container__past">
                <h3 className="medicalinfo-container__past-text">Medical information history</h3>
                <ul >
                    {pastMedicalInfoObjs.map(obj => (
                        <li >
                            <button key={obj._id} onClick={() => this.showPastMedicalInfoModel(obj._id)}>{` Check at ${formatDateTime(obj.CreatedAt)}`}</button>
                        </li>
                    )
                    )}
                </ul>
                {this.renderShowPastMedicalInfoModal()}

            </div>
        )
    }

    renderShowPastMedicalInfoModal() {
        const { isModalOpen, selectId } = this.state;
        const { pastMedicalInfoObjs } = this.props;
        const isPharmnetButtonOpen = false;

        if (selectId) {
            const obj = pastMedicalInfoObjs.find(obj => obj._id == selectId);
            return (<Dialog
                fullWidth={true}
                maxWidth="md"
                open={isModalOpen}
                onClose={this.handleModalClose}
            >
                <DialogTitle className="no-print">
                    Medical Information
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleModalClose}>
                        <i className="fa fa-close mx-2"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    {this.renderMedicalInfo(obj, isPharmnetButtonOpen)}
                </DialogContent>
                <DialogActions className="no-print">
                    <button className="btn btn-outline-secondary" onClick={this.handleModalClose}>Close</button>
                </DialogActions>
            </Dialog>)
        } else {
            return '';
        }
    }

    renderMedicalConditions(list, other) {
        return (
            <div className="col-md-4 px-3">
                <div className="mb-2"><strong>Medical conditions</strong></div>
                <ul>
                    {/* display No if array is empty and no Other */}
                    {(list.length === 0 && other === null) ?
                        <li>No</li>
                        :
                        list.sort().map((item) => {
                            return <li key={item}>{item}</li>;
                        })
                    }
                    {/* display Other if it exist */}
                    {other && other.map(item => {
                        return <li key={item._id}>{item.name}</li>;
                    })}
                </ul>
            </div>
        );
    }

    renderSurgicalHistory(list, other) {
        return (
            <div className="col-md-4 px-3">
                <div className="mb-2"><strong>Surgical history</strong></div>
                <ul>
                    {/* display No if array is empty and no Other */}
                    {(list.length === 0 && other === null) ?
                        <li>No</li>
                        :
                        list.map((item) => {
                            return <li key={item}>{item}</li>;
                        })
                    }
                    {/* display Other if it exist */}
                    {other && other.map((item, index) => {
                        return <li key={index}>{item}</li>;
                    })}
                </ul>
            </div>
        );
    }

    renderAllergy(list, other) {
        return (
            <div className="col-md-4 px-3">
                <div className="mb-2"><strong>Allergy to medications</strong></div>
                <ul>
                    {/* display No if array is empty and no Other */}
                    {(list.length === 0 && other === null) ?
                        <li>No</li>
                        :
                        list.map((item) => {
                            return <li key={item}>{item}</li>;
                        })
                    }
                    {/* display Other if it exist */}
                    {other && other.map((item) => {
                        return <li key={item._id}>{item.name}</li>;
                    })}
                </ul>
            </div>
        );
    }

    renderPharmacy(medicalInfoObj) {
        if (this.props.pharmacy !== false) {
            return (
                <>
                    <div className="mb-2"><strong>Preferred Pharmacy</strong></div>
                    <div>
                        {medicalInfoObj.defaultPharmacy && medicalInfoObj.defaultPharmacy.name}
                    </div>
                </>
            );
        } else {
            return '';
        }
    }

    renderMedicalInfo(medicalInfoObj,isPharmnetButtonOpen = true) {
      
        return(
            <div className="medicalinfo-container">
                        <div className="row margin-bottom">
                            <div className="col-md-4 mb-2">
                                <div className="mb-2"><strong>Gender</strong></div>
                                <div>{medicalInfoObj.Sex}</div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="mb-2"><strong>Do you drink alcoholic beverages?</strong></div>
                                <div>{medicalInfoObj.AlcoholHistory}</div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="mb-2"><strong>Do you smoke cigarettes?</strong></div>
                                <div>{medicalInfoObj.SmokingHistory}</div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            {this.renderMedicalConditions(medicalInfoObj.MedicalConditions, medicalInfoObj.otherMedicalConditions)}
                            {this.renderSurgicalHistory(medicalInfoObj.Surgeries, medicalInfoObj.otherSurgery)}
                            {this.renderAllergy(medicalInfoObj.AllergyToMedication, medicalInfoObj.otherAllergy)}
                        </div>
                        <hr />
                    </div>
        )

    }

    render() {
        const { medicalInfoObj, noMedicalInfo } = this.props;
          
        if (medicalInfoObj === undefined) {
            return (
                
                <div className="my-4">
                    Please wait, loading the medical information...
                </div>
                 
            );
        } else if (noMedicalInfo) {
            return (
                <div className="nomedicalinfo-container">
                    <div className="nomedicalinfo__tittle">
                        <p>You need to enter your medical information to proceed with the Sick Note request. Please click the Add/Edit button below:</p> 
                    </div>
                </div>
            );
        } else {
            return (
                <div className="medicalinfo">
                    {this.renderMedicalInfo(medicalInfoObj)}
                </div>
            );
        }
    }
}


MedicalInfo.propTypes = {
    dispatch: PropTypes.func.isRequired,
    status: PropTypes.string,
    pharmacy: PropTypes.bool,
    medicalInfoObj: PropTypes.object,
    noMedicalInfo: PropTypes.bool
};


function mapStateToProps(state) {
    const { status, medicalInfoObj, noMedicalInfo, pastMedicalInfoObjs } = state.medicalInfo;
   
   

    return {
        status,
        medicalInfoObj,
        noMedicalInfo,
        pastMedicalInfoObjs,
        
    };
}

const temp = connect(mapStateToProps)(withStyles(styles)(MedicalInfo));

export { temp as MedicalInfo };
