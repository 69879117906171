//com.southvanmed/src/actions
// alert 

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';



// AUTH
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADED = 'USER_LOADED';
export const USER_UPDATED = 'USER_UPDATED';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const RESET_PASSWORD='RESET_PASSWORD';
export const UPDATE_PASSWORD='UPDATE_PASSWORD';
export const UPDATE_MESSAGE='UPDATE_MESSAGE';
export const USER_FAILED_TO_CREATE_ACCOUNT = 'USER_FAILED_TO_CREATE_ACCOUNT';
export const USER_FAMILY_DOC_SET = 'USER_FAMILY_DOC_SET'

/* defining action types */
export const LOADER_LOADING = 'LOADER_LOADING';
export const LOADER_LOADED = 'LOADER_LOADED';
export const SET_LANG = 'SET_LANG';

export const SET_SICK_NOTE = 'SET_SICK_NOTE';
export const SET_SCHOOL_WORK_FORM = 'SET_SCHOOL_WORK_FORM';

// PHARMACY 

export const GET_ALL_PHARMACY = 'GET_ALL_PHARMACY'

// MEDICAL RECORD DOWNLOAD

export const MEDICAL_RECORD_DOWNLOAD_TOKEN_INVALID = 'MEDICAL_RECORD_DOWNLOAD_TOKEN_INVALID';

export const MEDICAL_RECORD_DOWNLOAD_TOKEN_VALID = 'MEDICAL_RECORD_DOWNLOAD_TOKEN_VALID'

export const MEDICAL_RECORD_GET_FILE_LIST_SUCCESS = 'MEDICAL_RECORD_GET_FILE_LIST_SUCCESS'


// SCHEDULE
export const SCHEDULE_DID_UPDATE = 'SCHEDULE_DID_UPDATE';
export const APPOINTMENT_DID_BOOK = 'APPOINTMENT_DID_BOOK';
export const SET_SCHEDULE_ON = 'SET_SCHEDULE_ON';
export const SCHEDULE_RESET = 'SCHEDULE_RESET';


// APPOINTMENT
export const APPOINTMENT_SELECTED = 'APPOINTMENT_SELECTED';
export const APPOINTMENT_RESCHEDULE_SELECTED = 'APPOINTMENT_RESCHEDULE_SELECTED';
export const APPOINTMENT_STATE_DID_CHANGE = 'APPOINTMENT_STATE_DID_CHANGE';
export const APPOINTMENT_RESET = 'APPOINTMENT_RESET';
export const CLEAR_SELECTED_APPOINTMENT = 'CLEAR_SELECTED_APPOINTMENT';

export const SCHEDULE = 'schedule';
export const DISPLAYSCHEDULE = 'displaySchedule';
export const CONFIRM = 'confirm';
export const PAYMENT = 'payment';
export const BOOKED = 'booked';
export const FINISHED = 'finished';
export const RESCHEDULE = 'reschedule';
