// com.southvanmed/src/actions/sicknote.action.js
import { medicalInfoService } from '../services';
import { medicalInfoConstant } from '../Constants';
import { alertAction } from './alert.action';
import { getPersonId, errorHandling, isEmptyObj } from '../_helpers';
import { sickNoteService } from '../services/sicknote.service';
import { setAlert } from "../actions/alert";
import { SET_SICK_NOTE, SET_SCHOOL_WORK_FORM } from "../actions/type";
import { history } from '../_helpers';
import {setToLoading,setToLoaded} from './loader';

export const sickNoteAction = {
    getByPerson,
    getById,
    change,
    save,
    validateSickNote,
    saveSickNoteWithFiles,
    // Add new methods for the post-payment workflow
    getSchoolWorkForm,
    uploadSchoolWorkFormFiles,
    submitSchoolWorkFormToOscar
};

// Helper function to calculate price based on page count
function calculatePrice(pageCount, formType) {
  if (formType === 'disability') {
    return 750; // Flat fee for disability
  }
  
  const firstPagePrice = 250;
  const additionalPagePrice = 75;
  
  if (pageCount <= 1) {
    return firstPagePrice;
  } else {
    return firstPagePrice + ((pageCount - 1) * additionalPagePrice);
  }
}

function getByPerson() {
    return dispatch => {
        medicalInfoService.getByPerson()
            .then(
                res => {
                    const array = res.data.medicalInfo;
                    const askmedUser = res.data.user;
                    const medicalInfoObj = getLatestMedicalInfo(array);
                    const pastMedicalInfoObjs = getPastMedicalInfo(array);
                    const actionObj = init(medicalInfoObj, askmedUser);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_PERSON,
                        ...actionObj,
                        pastMedicalInfoObjs,
                        askmedUser,
                    });
                },
                error => {
                    errorHandling('medicalInfo.action.getByPerson', error, dispatch);
                }
            );
    };
}

function getById(id) {
    return dispatch => {
        medicalInfoService.getById(id)
            .then(
                res => {
                    const actionObj = init(res.data);

                    dispatch({
                        type: medicalInfoConstant.GET_BY_ID,
                        ...actionObj
                    });
                },
                error => {
                    errorHandling('medicalInfo.action.getById', error, dispatch);
                }
            );
    };
}

function init(medicalInfoObj, user) {
    const noMedicalInfo = isEmptyObj(medicalInfoObj);

    if (noMedicalInfo) {
        medicalInfoObj = {
            Person: user.Person._id,
            Sex: '',
            MedicalConditions: [],
            Surgeries: [],
            AllergyToMedication: [],
            NoMedicalCondition: false,
            NoSurgery: false,
            NoAllergy: false,
            MedicationsCurrentlyTaken: [],
            supplements: '',
            otherItems: '',
            AlcoholHistory: '',
            SmokingHistory: '',
            defaultPharmacy: null,
            otherMedicalConditions: null,
            otherSurgery: null,
            otherAllergy: null,
          
        };
    } else {
        medicalInfoObj.NoMedicalCondition = medicalInfoObj.MedicalConditions.length === 0 && medicalInfoObj.otherMedicalConditions === null;
        medicalInfoObj.NoSurgery = medicalInfoObj.Surgeries.length === 0 && medicalInfoObj.otherSurgery === null;
        medicalInfoObj.NoAllergy = medicalInfoObj.AllergyToMedication.length === 0 && medicalInfoObj.otherAllergy === null;
    }

    return {
        medicalInfoObj,
        noMedicalInfo
    };
}

function getLatestMedicalInfo(medicalInfoList) {
    let medicalInfoObj = {};

    // get the latest medicalInfo object
    if (medicalInfoList !== undefined && medicalInfoList.length > 0) {
        // medicalInfoObj = [...medicalInfoList].pop();
        medicalInfoObj = [...medicalInfoList][0];
    }

    return medicalInfoObj;
}

function getPastMedicalInfo(medicalInfoList) {
    let pastMedicalInfo = [];

    if (medicalInfoList !== undefined && medicalInfoList.length > 1) {
        pastMedicalInfo = [...medicalInfoList].slice(1);   
    }
    return pastMedicalInfo;
}

function change(medicalInfoObj) {
    return {
        type: medicalInfoConstant.CHANGE,
        medicalInfoObj: medicalInfoObj
    };
}

function save(medicalInfoObj) {
    return dispatch => {
        medicalInfoService.save(medicalInfoObj)
            .then(
                res => {
                    const actionObj = init(res.data);
                    
                    dispatch({
                        type: medicalInfoConstant.SAVE,
                        ...actionObj
                    });


                    dispatch(alertAction.success('Medical information updated successfully.'));
                },
                error => {
                    errorHandling('manage-data.action.buildDrugs', error, dispatch);
                }
            );
    };
}

function saveSicknote (sickNoteObj){
        return async (dispatch) => {
            try{
                const res = await sickNoteService.save(sickNoteObj);
                
              
                
            } catch(error) {

            }

        }
}

function validateSickNote(sickNoteObj) {
  return async (dispatch) => {
    try {
      dispatch(setToLoading());

      // Different validation flows based on form type
      if (sickNoteObj.formType === 'school-work-form') {
        // Use the dedicated school work form validation
        const res = await sickNoteService.validateSchoolWorkForm(sickNoteObj);
        
        // Save the form data with calculated price from backend
        dispatch(saveSickNoteAction({
          ...sickNoteObj,
          calculatedPrice: res.data.extras.calculatedPrice
        }));
        
        dispatch(setToLoaded());
        history.push('/school-work-form/payment');
        return { success: true };
      } else {
        // Original sick note validation flow
        const res = await sickNoteService.validateSickNote(sickNoteObj);
        dispatch(saveSickNoteAction({
          isPrivate: !res.data.extras.isBCServiceValid,
          ...sickNoteObj
        }));
        
        dispatch(setToLoaded());
        history.push('/sick-note/payment');
        return { success: true };
      }
    } catch (error) {
      dispatch(setToLoaded());
      if (error.response) {
        return { success: false, error: error.response.data };
      }
      return { success: false, error: "Form validation failed" };
    }
  };
}
  
  // Updated function to save school-work form without files
function saveSickNoteWithFiles(sickNoteObj, files) {
  return async (dispatch) => {
    try {
      dispatch(setToLoading());
      
      // For the new workflow, we're not sending files here anymore
      // We're just saving the form data with page count and price
      
      // Create data object with form details
      const formData = {
        formType: sickNoteObj.formType,
        timeOffIsFor: sickNoteObj.timeOffIsFor,
        timeOffReason: sickNoteObj.timeOffReason,
        isAgreed: sickNoteObj.isAgreed,
        pageCount: sickNoteObj.pageCount,
        calculatedPrice: calculatePrice(parseInt(sickNoteObj.pageCount), sickNoteObj.timeOffIsFor)
      };
      
      // Add start and end dates if present (for sick notes)
      if (sickNoteObj.startTimeOff) {
        formData.startTimeOff = sickNoteObj.startTimeOff;
      }
      
      if (sickNoteObj.endOfTimeOff) {
        formData.endOfTimeOff = sickNoteObj.endOfTimeOff;
      }
      
      const res = await sickNoteService.saveSickNoteWithFiles(formData);
      
      dispatch(setToLoaded());
      
      if (res.data.success) {
        // Save the form data with the formId received from the server
        const updatedSickNoteObj = {
          ...sickNoteObj,
          formId: res.data.formId, // Store the formId from the response
          accessToken: res.data.accessToken, // Store the token in Redux state
          calculatedPrice: calculatePrice(parseInt(sickNoteObj.pageCount), sickNoteObj.timeOffIsFor)
        };
        
        // console.log("Form saved successfully with formId:", res.data.formId);
        // console.log("Access token received:", res.data.accessToken);
        
        // Dispatch action with updated object containing formId
        dispatch(saveSickNoteAction(updatedSickNoteObj));
        
        history.push('/school-work-form/payment');
        return { success: true };
      } else {
        return { success: false, error: res.data.error || "Failed to save form" };
      }
      
    } catch (error) {
      dispatch(setToLoaded());
      console.error("Error saving form:", error);
      if (error.response) {
        return { success: false, error: error.response.data };
      }
      return { success: false, error: "Failed to save form" };
    }
  };
}

function saveSickNoteAction(sickNoteObj) {
    // Determine which action type to use based on formType
    const actionType = sickNoteObj.formType === 'school-work-form' 
        ? SET_SCHOOL_WORK_FORM 
        : SET_SICK_NOTE;
        
    return ({
        type: actionType,
        payload: sickNoteObj
    });
}

// New function to get school work form by ID
function getSchoolWorkForm(formId) {
  return async (dispatch) => {
    try {
      dispatch(setToLoading());
      
      const response = await sickNoteService.getSchoolWorkForm(formId);
      
      dispatch(setToLoaded());
      
      if (response.data && response.data.success) {
        return { success: true, form: response.data.form };
      } else {
        return { 
          success: false, 
          error: response.data?.message || 'Failed to retrieve form' 
        };
      }
    } catch (error) {
      dispatch(setToLoaded());
      console.error('Error getting form:', error);
      return { 
        success: false, 
        error: error.response?.data?.message || 'Error retrieving form' 
      };
    }
  };
}

// New function to upload files after payment
function uploadSchoolWorkFormFiles(formData) {
  return async (dispatch) => {
    try {
      dispatch(setToLoading());
      
      const response = await sickNoteService.uploadSchoolWorkFormFiles(formData);
      
      dispatch(setToLoaded());
      
      if (response.data && response.data.success) {
        return { success: true };
      } else {
        return { 
          success: false, 
          error: response.data?.message || 'Failed to upload files' 
        };
      }
    } catch (error) {
      dispatch(setToLoaded());
      console.error('Error uploading files:', error);
      return { 
        success: false, 
        error: error.response?.data?.message || 'Error uploading files' 
      };
    }
  };
}

// New function to submit school work form to Oscar
function submitSchoolWorkFormToOscar(formId) {
  return async (dispatch) => {
    try {
      dispatch(setToLoading());
      
      const response = await sickNoteService.submitSchoolWorkFormToOscar(formId);
      
      dispatch(setToLoaded());
      
      if (response.data && response.data.success) {
        return { success: true };
      } else {
        return { 
          success: false, 
          error: response.data?.message || 'Failed to submit form to Oscar' 
        };
      }
    } catch (error) {
      dispatch(setToLoaded());
      console.error('Error submitting to Oscar:', error);
      return { 
        success: false, 
        error: error.response?.data?.message || 'Error submitting form to Oscar' 
      };
    }
  };
}