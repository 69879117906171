// com.southvanmed/src/services/store.js
import {createStore,applyMiddleware} from 'redux';

import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from "redux-thunk";
import rootReducer from './reducers';

const initialState  = {}; // empty state in initial state

const middleWare = [thunk]; // array of middlewares 

const store = createStore(rootReducer,initialState,composeWithDevTools(
    applyMiddleware(...middleWare)
));

export default store;