// src/components/SchoolWorkForm/SchoolWorkFormUpload.js
import React, { Component } from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

// Components
import Form from 'react-bootstrap/Form';
import CommonPageHeader from '../CommonPageHeader/CommonPageHeader';
import FileUploaderController from '../../pages/file/file-uploader-controller';

// Actions
import { setToLoaded, setToLoading } from '../../actions/loader';
import { setAlert } from '../../services/sweetAlert';
import { sickNoteService } from '../../services/sicknote.service';
import { history } from '../../_helpers';

class SchoolWorkFormUpload extends Component {
  constructor(props) {
    super(props);
    
    // Check both possible URL structures
    const formId = this.props.match.params.formId || null;
    const token = decodeURIComponent(this.props.match.params.token) || null;
    
    // console.log("URL params - formId:", formId, "token:", token);
    
    this.state = {
      formId: formId,
      token: token,
      loading: true,
      error: null,
      formData: null,
      file1: [],
      file2: [],
      file3: [],
      uploading: false,
      uploadComplete: false,
      uploadSuccess: false,
      reattempt: false,
      authError: false,
      formComplete: false
    };
    
    this.changeFile = this.changeFile.bind(this);
    this.isNoAttachment = this.isNoAttachment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }
  
  async componentDidMount() {
    let { token, formId } = this.state;
    
    // Check localStorage as a fallback (for legacy support)
    if (!token && !formId) {
      formId = localStorage.getItem('schoolWorkFormId');
      
      if (formId) {
        // console.log("Using formId from localStorage:", formId);
        this.setState({ formId });
      }
    }
    
    if (!token && !formId) {
      this.setState({ 
        loading: false, 
        error: 'No form identifier found. Please check your URL or start the process again.' 
      });
      return;
    }
    
    try {
      // Fetch form data based on what identifier we have
      this.props.setToLoading();
      let response;
      
      if (token) {
        // console.log("Fetching form using token:", token);
        try {
          response = await sickNoteService.getSchoolWorkFormByToken(token);
        } catch (error) {
          // Check if this is an authentication error (401)
          if (error.response && error.response.status === 401) {
            this.props.setToLoaded();
            this.setState({ 
              loading: false, 
              authError: true,
              error: 'Please log in to access this form. Your session may have expired.'
            });
            return;
          }
          throw error; // Re-throw if it's not an auth error
        }
      } else {
        // console.log("Fetching form using formId:", formId);
        try {
          response = await sickNoteService.getSchoolWorkForm(formId);
        } catch (error) {
          // Check if this is an authentication error (401)
          if (error.response && error.response.status === 401) {
            this.props.setToLoaded();
            this.setState({ 
              loading: false, 
              authError: true,
              error: 'Please log in to access this form. Your session may have expired.'
            });
            return;
          }
          throw error; // Re-throw if it's not an auth error
        }
      }
      
      this.props.setToLoaded();
      
      if (!response.data?.success) {
        this.setState({ 
          loading: false, 
          error: 'Could not retrieve form data. Please try again.' 
        });
        return;
      }
      
      const formData = response.data.form;
      // console.log('whats formdata',formData)
      
      // Store both identifiers for later use
      this.setState({
        formId: formData.formId,
        token: token || formData.accessToken,
        formData
      });
      
      // Check if payment is complete
      if (!formData.paymentComplete) {
        this.setState({ 
          loading: false, 
          error: 'This form has not been paid for. Please complete payment first.' 
        });
        return;
      }
      
      // Check if the form has been fully processed
      if (formData.uploadedToOscar) {
        // Show success message instead of redirecting
        this.setState({
          loading: false,
          formComplete: true,
          formData,
          error: null
        });
        return;
      }
      
      // Check if files have been uploaded but Oscar processing failed
      if (formData.filesUploaded) {
        this.setState({
          loading: false,
          reattempt: true,
          formData
        });
        return;
      }
      
      // Regular case - form paid but files not yet uploaded
      this.setState({
        loading: false
      });
      
    } catch (error) {
      this.props.setToLoaded();
      console.error('Error fetching form data:', error);
      this.setState({ 
        loading: false, 
        error: 'An error occurred while retrieving form data.' 
      });
    }
  }
  
  changeFile(files, fileKey) {
    this.setState({ [fileKey]: files });
    // console.log(`Files updated for ${fileKey}:`, files);
  }
  
  isNoAttachment() {
    const { file1, file2, file3 } = this.state;
    
    const _isFileEmpty = (f) => !f || f.length === 0;
    
    return _isFileEmpty(file1) && _isFileEmpty(file2) && _isFileEmpty(file3);
  }
  
  handleLogin() {
    // Save the complete URL including token for redirect after login
    const currentUrl = window.location.pathname + window.location.search;
    
    // For token URLs, we need to keep the full encoded URL
    if (this.state.token) {
      const fullUrl = window.location.href;
      localStorage.setItem('redirectAfterLogin', fullUrl);
    } else {
      localStorage.setItem('redirectAfterLogin', currentUrl);
    }
    
    // console.log("Saved redirect URL:", localStorage.getItem('redirectAfterLogin'));
    
    // Redirect to login page
    history.push('/login');
  }
  
  async handleSubmit(e) {
    e.preventDefault();
    
    const { formId, token, file1, file2, file3, reattempt } = this.state;
    
    // If this is a reattempt, we can skip file validation
    if (!reattempt && this.isNoAttachment()) {
      setAlert({
        message: 'Please upload at least one file.',
        icon: 'error'
      });
      return;
    }
    
    this.setState({ uploading: true });
    this.props.setToLoading();
    
    try {
      // If this is a reattempt, skip file upload and just try Oscar submission
      if (!reattempt) {
        // Create form data for file upload
        const formData = new FormData();
        
        // Add formId if using ID-based approach
        if (!token && formId) {
          formData.append('formId', formId);
        }
        
        // Append files
        if (file1 && file1.length > 0) {
          formData.append('files', file1[0]);
        }
        
        if (file2 && file2.length > 0) {
          formData.append('files', file2[0]);
        }
        
        if (file3 && file3.length > 0) {
          formData.append('files', file3[0]);
        }
        
        // Upload files using the appropriate method
        let response;
        try {
          if (token) {
            // console.log("Uploading files using token:", token);
            response = await sickNoteService.uploadSchoolWorkFormFilesByToken(token, formData);
          } else {
            // console.log("Uploading files using formId:", formId);
            response = await sickNoteService.uploadSchoolWorkFormFiles(formData);
          }
        } catch (error) {
          // Check if this is an authentication error (401)
          if (error.response && error.response.status === 401) {
            this.props.setToLoaded();
            this.setState({ 
              uploading: false,
              authError: true,
              error: 'Your session has expired. Please log in again to continue.'
            });
            return;
          }
          throw error; // Re-throw if it's not an auth error
        }
        
        if (!response.data?.success) {
          this.props.setToLoaded();
          this.setState({ 
            uploading: false,
            error: response.data?.message || 'Failed to upload files. Please try again.'
          });
          return;
        }
        
        // Capture any token that might be returned
        if (response.data.accessToken && !this.state.token) {
          this.setState({ token: response.data.accessToken });
        }
      }
      
      // Complete the submission to Oscar
      let oscarResponse;
      try {
        if (token) {
          // console.log("Completing Oscar submission using token:", token);
          oscarResponse = await sickNoteService.submitSchoolWorkFormToOscarByToken(token);
        } 
        // else {
        //   console.log("Completing Oscar submission using formId:", formId);
        //   oscarResponse = await sickNoteService.submitSchoolWorkFormToOscar(formId);
        // }
      } catch (error) {
        // Check if this is an authentication error (401)
        if (error.response && error.response.status === 401) {
          this.props.setToLoaded();
          this.setState({ 
            uploading: false,
            authError: true,
            error: 'Your session has expired. Please log in again to continue.'
          });
          return;
        }
        throw error; // Re-throw if it's not an auth error
      }
      
      this.props.setToLoaded();
      
      if (oscarResponse.data?.success) {
        this.setState({
          uploading: false,
          uploadComplete: true,
          uploadSuccess: true
        });
        
        // Clear any stored formId from localStorage
        localStorage.removeItem('schoolWorkFormId');
        
        // Redirect to success page after a short delay
        // setTimeout(() => {
        //   history.push('/school-work-form/payment/success');
        // }, 2000);
      } else if (oscarResponse.data?.alreadyProcessed) {
        // Form was already fully processed
        this.setState({
          uploading: false,
          formComplete: true,
          formData: {
            ...this.state.formData,
            uploadedToOscar: true,
          }
        });
        
        localStorage.removeItem('schoolWorkFormId');
      } else {
        this.setState({
          uploading: false,
          error: oscarResponse.data?.error || 'Error submitting to Oscar. Please try again.'
        });
      }
      
    } catch (error) {
      this.props.setToLoaded();
      console.error('Error in upload/submission process:', error);
      this.setState({ 
        uploading: false,
        error: 'An error occurred. Please try again.'
      });
    }
  }
  
  handleCancel() {
    history.push('/dashboard');
  }
  
  render() {
    const { 
      loading, 
      error, 
      formData, 
      uploading, 
      uploadComplete,
      uploadSuccess,
      reattempt,
      token,
      authError,
      formComplete
    } = this.state;
    
    if (loading) {
      return (
        <>
          <CommonPageHeader title="Upload Documents" subtitle="Request a Form" />
          <Container>
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mt-3">Loading form data...</p>
            </div>
          </Container>
        </>
      );
    }
    
    // Authentication error UI
    if (authError) {
      return (
        <>
          <CommonPageHeader title="Authentication Required" subtitle="Request a Form" />
          <Container>
            <Alert variant="warning" className="mt-4">
              <Alert.Heading>Login Required</Alert.Heading>
              <p>{error || "You need to be logged in to access this form."}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <button 
                  className="primary_btn" 
                  onClick={this.handleLogin}
                >
                  Sign In
                </button>
              </div>
            </Alert>
          </Container>
        </>
      );
    }
    
    // Form already completed UI
    if (formComplete) {
      return (
        <>
          <CommonPageHeader title="Form Already Submitted" subtitle="Request a Form" />
          <Container>
            <Alert variant="success" className="mt-4">
              <Alert.Heading>Form Already Processed</Alert.Heading>
              <p>This form has already been successfully submitted and is being processed by our team.</p>
              <hr />
              <p className="mb-0">
                {formData?.submittedDate ? (
                  <small>Submitted on: {new Date(formData.submittedDate).toLocaleString()}</small>
                ) : null}
              </p>
              <div className="d-flex justify-content-end mt-3">
                <button 
                  className="primary_btn" 
                  onClick={() => history.push('/dashboard')}
                >
                  Return to Dashboard
                </button>
              </div>
            </Alert>
          </Container>
        </>
      );
    }
    
    if (error && !authError) {
      return (
        <>
          <CommonPageHeader title="Upload Documents" subtitle="Request a Form" />
          <Container>
            <Alert variant="danger" className="mt-4">
              <Alert.Heading>Error</Alert.Heading>
              <p>{error}</p>
              <div className="d-flex justify-content-end">
                <button 
                  className="primary_btn" 
                  onClick={() => history.push('/dashboard')}
                >
                  Return to Dashboard
                </button>
              </div>
            </Alert>
          </Container>
        </>
      );
    }
    
    if (uploadComplete) {
      if (uploadSuccess) {
        return <Redirect to="/school-work-form/payment/success" />;
      } else {
        return (
          <>
            <CommonPageHeader title="Upload Documents" subtitle="Request a Form" />
            <Container>
              <Alert variant="danger" className="mt-4">
                <Alert.Heading>Upload Failed</Alert.Heading>
                <p>There was a problem uploading your documents. Please try again.</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <button 
                    className="primary_btn" 
                    onClick={() => this.setState({ uploadComplete: false })}
                  >
                    Try Again
                  </button>
                </div>
              </Alert>
            </Container>
          </>
        );
      }
    }
    
    if (reattempt) {
      return (
        <>
          <CommonPageHeader title="Complete Submission" subtitle="Request a Form" />
          <Container>
            <Alert variant="warning" className="mt-4">
              <Alert.Heading>Files Already Uploaded</Alert.Heading>
              <p>
                Your files have been uploaded, but we need to complete the submission process.
                Please click the button below to finalize your submission.
              </p>
              <div className="d-flex justify-content-end">
                <button 
                  className="primary_btn"
                  onClick={this.handleSubmit}
                  disabled={uploading}
                >
                  {uploading ? 'Processing...' : 'Complete Submission'}
                </button>
              </div>
            </Alert>
            
            {/* Display permanent link for reference */}
            {token && (
              <div className="mt-4 p-3 border rounded bg-light">
                <p className="mb-1"><strong>Bookmark This Link</strong></p>
                <p className="mb-0">
                  You can use this link to return to this upload page if needed:
                </p>
                <small className="text-muted">
                  <code>{window.location.origin}/school-work-form/upload-token/{token}</code>
                </small>
              </div>
            )}
          </Container>
        </>
      );
    }
    
    return (
      <>
        <CommonPageHeader title="Upload Documents" subtitle="Request a Form" />
        <div className="file-upload-container">
          <Container>
            <div className="mt-3">
              <Alert variant="success">
                <Alert.Heading>Payment Successful!</Alert.Heading>
                <p>
                  Your payment has been processed successfully. Please upload your form documents now.
                </p>
              </Alert>
              
              <p>Please upload your {formData.timeOffIsFor} forms that need to be completed:</p>
            </div>
            
            <Form className="mt-1">
              <Form.Group className="mb-3">
                <Form.Label>Upload your form(s):</Form.Label>
                <div className="p-3 border rounded">
                  <FileUploaderController 
                    changeFile={this.changeFile} 
                    multiple={false} 
                    isButtonDisabled={uploading} 
                    isNoAttachment={this.isNoAttachment}
                    isFirstFileMandatory={true}
                  />
                </div>
                <Form.Text className="text-muted">
                  You can upload up to 3 files (maximum 10MB total). Supported formats: PDF, JPG, PNG.
                </Form.Text>
              </Form.Group>
              
              <Row>
                <Col className="d-flex justify-content-between">
                  <button 
                    className="primary_btn bg_secondary" 
                    onClick={this.handleCancel}
                    disabled={uploading}
                  >
                    Cancel
                  </button>
                  <button 
                    className="primary_btn" 
                    onClick={this.handleSubmit}
                    disabled={uploading}
                  >
                    {uploading ? (
                      <>
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        Uploading...
                      </>
                    ) : (
                      'Submit Files'
                    )}
                  </button>
                </Col>
              </Row>
            </Form>
            
            {/* Display permanent link for reference */}
            {token && (
              <div className="mt-4 p-3 border rounded bg-light">
                <p className="mb-1"><strong>Bookmark This Link</strong></p>
                <p className="mb-0">
                  You can bookmark this page or save this link to return to this upload page if needed:
                </p>
                <small className="text-muted">
                  <code>{window.location.origin}/school-work-form/upload-token/{token}</code>
                </small>
              </div>
            )}
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  setToLoading,
  setToLoaded
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolWorkFormUpload);