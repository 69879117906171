import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import axios from "axios"; // Add axios for file upload

//components
import Form from "react-bootstrap/Form";

//Actions
import { loadUser, setUser, setShouldUpdate } from "../../actions/auth";
import { setToLoaded, setToLoading } from "../../actions/loader";
import { sickNoteAction } from '../../actions/sicknote.action';

import { history } from '../../_helpers';
// services

import "react-datepicker/dist/react-datepicker.css";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";

import { setAlert } from '../../services/sweetAlert';
import FileUploaderController from "../file/file-uploader-controller";
import config from "../../config"; // Add config import

class SickNote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      reason: "",
      note: "",
      isAgree: false,
      formType: 'sick-note', // default 
      organization: "", // for school-work-form
      pageCount: 1, // Add pageCount with default value 1
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.formatReason = this.formatReason.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
  }
  // Add a method to calculate price based on page count
  calculatePrice(pageCount, reason) {
    if (reason === 'disability') {
      return 750; // Flat fee for disability
    }
    const firstPagePrice = 250;
    const additionalPagePrice = 75;
    
    if (pageCount <= 1|| !pageCount) {
      return firstPagePrice;
    } else {
      return firstPagePrice + ((pageCount - 1) * additionalPagePrice);
    }
  }

  componentDidMount() {
    // First check the URL path to determine the default form type
    const isSchoolWorkForm = window.location.pathname.includes('school-work-form');
    let formType = isSchoolWorkForm ? 'school-work-form' : 'sick-note';
    
    // Then check if there's an override in the query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const queryFormType = urlParams.get('formType');
    
    if (queryFormType) {
      formType = queryFormType;
    }
    
    // Set the state
    this.setState({ formType });
  }

  setStartDate(startDate) {
    this.setState({
      startDate,
    });
  }

  setEndDate(endDate) {
    this.setState({
      endDate,
    });
  }

  // Handle file changes for uploads
  changeFile(files, fileKey) {
    this.setState({ [fileKey]: files });
    console.log(`Files updated for ${fileKey}:`, files);
  }

  // Check if there are no attachments
  isNoAttachment() {
    const { file1, file2, file3 } = this.state;
    
    const _isFileEmpty = (f) => !f || f.length === 0;
    
    return _isFileEmpty(file1) && _isFileEmpty(file2) && _isFileEmpty(file3);
  }

  // Helper method to format reason text
  formatReason() {
    let reason = "";
    if (this.state.reason) {
      reason += "from ";
      if (this.state.reason === "work")
        reason += "work";
      if (this.state.reason === "school")
        reason += "school";
      if (this.state.reason === "workAndSchool")
        reason += "work and school";
    }
    return reason;
  }

  async onSubmit(e) {
    e.preventDefault();
    
    const { formType, file1, file2, file3 } = this.state;
    
    if (formType === 'school-work-form') {
      // Validate required fields for school/work form
      if (!this.state.reason) {
        setAlert({
          message: "Please select the form type",
          icon: 'error'
        });
        return;
      }
      
      if (!this.state.note) {
        setAlert({
          message: "Please provide details about the form",
          icon: 'error'
        });
        return;
      }
      
      if (!this.state.isAgree) {
        setAlert({
          message: "Please agree to the terms",
          icon: 'error'
        });
        return;
      }

      // Make sure pageCount is valid
      const pageCount = parseInt(this.state.pageCount);
      if (isNaN(pageCount) || pageCount < 1 || pageCount > 99) {
        setAlert({
          message: "Please enter a valid number of pages (1-99)",
          icon: 'error'
        });
        return;
      }
      // Create data object for the form with price calculation
      const calculatedPrice = this.calculatePrice(pageCount, this.state.reason);
      // Create data object for the form
      const sickNoteObj = {
        formType: 'school-work-form',
        timeOffIsFor: this.state.reason,
        timeOffReason: this.state.note,
        isAgreed: this.state.isAgree,
        pageCount:pageCount,
        calculatedPrice:calculatedPrice,
      };
      
      
      try {
        // First validate the form without files
        const validationResult = await this.props.validateSickNote(sickNoteObj);
        
        if (!validationResult.success) {
          setAlert({
            message: validationResult.error || "Failed to validate form",
            icon: 'error'
          });
          return;
        }
        
        // Then submit with files
        const result = await this.props.saveSickNoteWithFiles(sickNoteObj);
        
        if (!result.success) {
          setAlert({
            message: result.error || "Failed to submit form",
            icon: 'error'
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setAlert({
          message: error.response?.data || "An error occurred",
          icon: 'error'
        });
      }
    } else {
      // Original sick note logic
      let res = await this.props.validateSickNote({
        timeOffIsFor: this.state.reason,
        timeOffReason: this.state.note, 
        startTimeOff: this.state.startDate, 
        endOfTimeOff: this.state.endDate,
        isAgreed: this.state.isAgree
      });
      
      // Error handling
      if (res && !res.success) {
        setAlert({
          message: res.error,
          icon: 'error'
        });
      }
    }
}

  onCancel() {
    console.log("go back to list");
    history.push('dashboard');
  }

  componentDidUpdate() {
    if (
      moment(this.state.endDate).isAfter(moment(this.state.startDate).add(6, "days"))
      || moment(this.state.endDate).isBefore(moment(this.state.startDate))
    ) this.setEndDate(moment(this.state.startDate).toDate());
  }

  isTwelveOlder() {
    const { year, month, day } = this.props.user.birthday;
    let dob = moment(`${year}-${month}-${day}`);
    let years = moment().diff(moment(dob), 'years');
     
    if (years > 12) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { formType } = this.state;
    
    // Set title based on form type
    const pageTitle = formType === 'school-work-form' 
      ? "Request a Form" 
      : "Request Sick Note";
    const pageSubtitle = formType === 'school-work-form' 
      ? "Request a Form" 
      : "Sick Note";
    
    return (
      <>
      <CommonPageHeader title={pageTitle} subtitle={pageSubtitle} />
      <div className="sick-note-container">
        <Container>
          <div className="mt-3">
            <p>Please answer the questions below: </p>
          </div>
          <Form className="mt-1">
            {formType === 'school-work-form' ? (
              // School/Work form fields
              <>
              <Form.Group className="mb-3">
              <Form.Label>Is this form for school, work, or disability?</Form.Label>
                <select 
                  className="form-control" 
                  value={this.state.reason}
                  onChange={({ target: { value } }) => {
                    // Reset page count to 1 if switching to disability
                    if (value === 'disability') {
                      this.setState({ 
                        reason: value,
                        pageCount: 1 // Reset to 1 for disability
                      });
                      console.log('called page reset',this.state.pageCount)
                    } else {
                      // Just update reason for other options
                      this.setState({ reason: value });
                    }
                  }}
                >
                  <option value="">Select option</option>
                  <option value="school">School form</option>
                  <option value="work">Work form</option>
                  <option value="disability">Disability form</option>
                </select>
              </Form.Group>
              
              {/* Form details field - reusing note field */}
              <Form.Group className="mb-3">
                <Form.Label>Explain the reason you need this form to be filled out:</Form.Label>
                <Form.Control as="textarea" rows={4} maxLength="400"
                  value={this.state.note}
                  onChange={(e) => this.setState({ note: e.target.value })}
                  placeholder="Provide details about what the form requires"
                />
              </Form.Group>
              
              {/* Page count selector, don't render if its disability */}
              {this.state.reason !== 'disability' && (
              <Form.Group className="mb-3">
                <Form.Label>Number of pages in your form:</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="99"
                  value={this.state.pageCount}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    this.setState({ 
                      pageCount: value < 1 ? 1 : value > 99 ? 99 : value 
                    });
                  }}
                />
                <Form.Text className="text-muted">
                  Enter the number of pages that need to be filled out (1-99)
                </Form.Text>
              </Form.Group>
            )}
              
              {/* Price display */}
              {this.state.reason !== 'disability' && (
              <Form.Group className="mb-3">
                <Form.Label>Estimated price:</Form.Label>
                <div className="p-3 border rounded bg-light">
                  <p className="mb-1"><strong>${this.calculatePrice(this.state.pageCount, this.state.reason)}</strong></p>
                  <small className="text-muted">
                    First page: $250, each additional page: $75
                  </small>
                </div>
              </Form.Group>
              )}
            </>
            ) : (
              // Original sick note fields
              <>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Is the time off from school, work, or both?
                  </Form.Label>
                  {this.isTwelveOlder() ? 
                    <select
                      className="form-control"
                      value={this.state.reason}
                      onChange={({ target: { value } }) =>
                        this.setState({ reason: value })
                      }
                    >
                      <option value="">Select option</option>
                      <option value="work">Work</option>
                      <option value="school">School</option>
                      <option value="workAndSchool">Work and school</option>
                    </select> : 
                    <select
                      className="form-control"
                      value={this.state.reason}
                      onChange={({ target: { value } }) =>
                        this.setState({ reason: value })
                      }
                    >
                      <option value="">Select option</option>
                      <option value="school">School</option>
                    </select>
                  }
                </Form.Group>

                <Row>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>Start of the time off (IMPORTANT: cannot be changed later):</Form.Label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.startDate}
                        onChange={(date) => this.setStartDate(date)}
                        minDate={moment().subtract(60, "days").toDate()}
                        maxDate={moment().add(1, "days").toDate()} 
                        dateFormat="dd MMM yyyy"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>End of time off (IMPORTANT: cannot be changed later):</Form.Label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.endDate}
                        onChange={(date) => this.setEndDate(date)}
                        minDate={moment(this.state.startDate).toDate()}
                        maxDate={moment(this.state.startDate).add(6, "days").toDate()}
                        dateFormat="dd MMM yyyy"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-1">
                  <Form.Label>
                    {`Please explain the reason for you being away ${this.formatReason()} between ${moment(this.state.startDate).format("DD MMM yyyy")} and ${moment(this.state.endDate).format("DD MMM yyyy")}:`}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    maxLength="400"
                    value={this.state.note}
                    onChange={(e) => {
                      this.setState({
                        note: e.target.value,
                      });
                    }}
                    placeholder="Provide reason"
                  />
                </Form.Group>
              </>
            )}
            
            {/* Agreement checkbox with appropriate text */}
            <Form.Group className="mb-2">
              <Form.Check>
                <Form.Check.Input id="agreement" type="checkbox" checked={this.state.isAgree} className="big-checkbox"
                  onChange={() => {
                    this.setState({ isAgree: !this.state.isAgree });
                  }}/>
                <Form.Check.Label htmlFor="agreement" className="checkbox-label">
                  {formType === 'school-work-form' 
                    ? `I understand and agree that the completion of medical forms is at the provider's discretion. I acknowledge that the fees I pay are non-refundable and that forms can only be completed after all required medical information has been received. The South Vancouver Medical Clinic may contact me for additional documents if needed, and I may be asked to speak with a provider over the phone or visit the clinic in person. If there are extensive medical records to review, an additional fee may apply. Payment is for the review of medical records, not merely for the completion of forms, especially in the case of disability applications.`
                    : `I declare that I am truthful about the information provided to the South Vancouver Medical Clinic healthcare provider. If I have severe symptoms that warrant a visit to the healthcare provider, such as significant pain or difficulty breathing, I agree to follow up with a healthcare provider immediately by booking at http://SouthVanMed.com/login or going to the hospital emergency department. I also agree with sick note fees being non-refundable, sick notes taking up to 24 hours to be provided, and that the sick note may be modified by the healthcare provider if deemed necessary.`}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            
            {/* Buttons remain the same */}
            <Row>
              <Col className="d-flex justify-content-between">
                <button className="primary_btn bg_secondary" onClick={this.onCancel}>
                  Cancel
                </button>
                <button className="primary_btn" onClick={this.onSubmit}>
                  Continue
                </button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
    loadUser,
    setUser,
    setShouldUpdate,
    setToLoading,
    setToLoaded,
    validateSickNote: sickNoteAction.validateSickNote,
    saveSickNoteWithFiles: sickNoteAction.saveSickNoteWithFiles,
    saveSickNoteAction: sickNoteAction.saveSickNoteAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SickNote);