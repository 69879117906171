//com.southvanmed/src/app.js
import React, { Fragment } from "react";

import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import ScrollTop from "./components/ScrollTop";
import AllContext from "./context/AllContext";

// helpers
import { history } from "./_helpers";

// pages
import HomeTwoNavBar from "./pages/HomeTwo/HomeTwoNavBar/HomeTwoNavBar";

//@TODO
// format to login footer
import NavBarLoggedIn from "./pages/HomeTwo/NavbarLoggedIn/NavBarLoggedIn";

import AboutUs from "./pages/AboutUs/AboutUs";
import Appointment from "./pages/Appointment/Appointment";
import ClinicPolicy from "./pages/ClinicPolicy/ClinicPolicy";
import FAQ from "./pages/FAQ/FAQ";
import IMMI from "./pages/Immigration/Immigration";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Contact from "./pages/Contact/Contact";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import HomeTowFooter from "./pages/HomeTwo/HomeTwoFooter/HomeTwoFooter";
import NotFound from "./pages/NotFound/NotFound";

import Dashboard from "./pages/Dashboard/Dashboard";

import Messages from "./pages/Messages/messages";
import Message from "./pages/Messages/message";

import MyAppointment from "./pages/MyAppointment/MyAppointment";

import LabTesting from "./pages/LabTesting/LabTesting"

import SickNote from "./pages/SickNote/sick-note";
import SickNoteLanding from "./pages/SickNote/SickNoteLanding";
import SickNoteValidation from "./pages/SickNoteValidation/SickNoteValidation";
import MyAccount from "./pages/MyAccount/MyAccount";
import Logout from "./pages/Logout/Logout";

import { SicknoteMedicalInfo } from "./pages/MedicalInfo/SicknoteMedicalInfo";
import { EditMedicalInfoPage } from "./pages/MedicalInfo/EditMedicalInfoPage";

import MedicalRecordsDownload from "./pages/file/medicalRecordsDownload";

import PublicRoute from "./components/Layout/PublicRoute";
import PrivateRoute from "./components/Layout/PrivateRoute";

import { Payment } from "./components/Payment/payment";
import SickNotePaymentSuccess from "./components/Payment/SickNotePaymentSuccess";
import SchoolWorkFormUpload from './components/SchoolWorkForm/SchoolWorkFormUpload';

import ToImmigration from "./pages/Redirect/ToImmigration";

import axios from "axios";
import IdleTimer from "react-idle-timer";

import store from "./store";
import { logoutAction, loadUser } from "./actions/auth";
import { getAllPharmacy } from "./actions/pharmacy";
import config from "./config";
import { setLang as setLangAction } from "./actions/lang";
import { setLang as setLangService } from "./services/lang";
import { setToLoading } from "./actions/loader";

import Loader from "./components/Layout/Loader";

import "./App.css";
import { Redirect } from "react-router-dom";

axios.interceptors.response.use(
  (response) => {
    // console.log(response)
    return response;
  },
  (err) => {
    if (err.response && err.response.data.type == "tokenNotValid") {
      setTimeout(() => {
        // console.log('redirect to login')
        store.dispatch(logoutAction());
      }, 1000);
      return Promise.reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);

    const lang = new URL(window.location.href).searchParams.get("lang");
    if (lang) {
      store.dispatch(setLangAction(lang));
      setLangService(lang);
      history.push(window.location.pathname);

      // window.location.replace("http://www.w3schools.com")
    }
  }

  onIdle() {
    console.log("idle handler");
    this.props.logoutAction();
  }

  componentDidMount() {
    store.dispatch(loadUser());
    store.dispatch(getAllPharmacy());
  }

  render() {
    const { isAuthenticated } = this.props;

    return (
      <>
        <AllContext>
          {/* <Loader /> */}
          <Router history={history}>
            {/* {!isAuthenticated ? <HomeTwoNavBar /> : <NavBarLoggedIn />} */}
            {!isAuthenticated ? (
              <Route path="/" component={HomeTwoNavBar} />
            ) : (
              <Route path="/" component={NavBarLoggedIn} />
            )}

            <ScrollTop />
            <Switch>
              {/* <PublicRoute exact path="/" component={HomeTwo} /> */}
              <Route exact path="/" component={HomeTwo} />

              <Route exact path="/clinic-policy" component={ClinicPolicy} />
              <Route path="/faq" component={FAQ} />

              <Route
                path="/immigration"
                render={() => <Redirect to="/immi" />}
              />

              <Route path="/immi" component={IMMI} />
              <Route path="/book-appointment" component={Appointment} />
              <Route path="/login" component={Login} />

              <Route path="/login-cn" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/logout" component={Logout} />

              <Route path="/BOOKONLINE" component={ToImmigration} />
              <Route path="/about" component={AboutUs} />

              <Route path="/lab-testing" component={LabTesting} />

              <Route path="/sicknote" component={SickNoteLanding} />
              <Route path="/validation" component={SickNoteValidation} />

              <Route path="/contact" component={Contact} />
              <Route
                path="/medical/records/:token"
                component={MedicalRecordsDownload}
              />

              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/message" component={Messages} />
              <PrivateRoute exact path="/message/:id" component={Message} />

              <PrivateRoute
                exact
                path="/appointments/me"
                component={MyAppointment}
              />

              <PrivateRoute exact path="/account" component={MyAccount} />
              <PrivateRoute
                exact
                path="/medicalInfo"
                component={SicknoteMedicalInfo}
              />
              <PrivateRoute
                exact
                path="/medicalInfo/edit"
                component={EditMedicalInfoPage}
              />
              <PrivateRoute exact path="/sick-note" component={SickNote} />
              <PrivateRoute
                exact
                path="/sick-note/payment"
                component={Payment}
              />
              <PrivateRoute
                exact
                path="/sick-note/payment/success"
                component={SickNotePaymentSuccess}
              />
              <PrivateRoute exact path="/school-work-form" component={SickNote} />
              <PrivateRoute
                exact
                path="/school-work-form/payment"
                component={Payment}
              />
              <PrivateRoute 
                path="/school-work-form/upload/:formId" 
                component={SchoolWorkFormUpload} 
              />
              <Route 
                path="/school-work-form/upload-token/:token" 
                component={SchoolWorkFormUpload} 
              />

              <PrivateRoute
                exact
                path="/school-work-form/payment/success"
                component={SickNotePaymentSuccess}
              />

              <Route path="/not-found" component={NotFound} />
              <Route path="*" component={NotFound} />
            </Switch>
            <HomeTowFooter />
          </Router>
        </AllContext>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  logoutAction,
  setToLoading,
  getAllPharmacy,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
