import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Container } from "react-bootstrap";

import { history } from '../../_helpers'
import CommonPageHeader from "../CommonPageHeader/CommonPageHeader";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const PaymentSuccess = (props) => {
    const { user = {}, location } = props;
    
    // Determine if this is a school/work form or sick note based on the URL path
    const isSchoolWorkForm = location.pathname.includes('school-work-form');
    
    // Set appropriate text based on form type
    const pageTitle = isSchoolWorkForm 
        ? "Form Request Payment Successful" 
        : "Sick Note Payment Successful";
        
    const pageSubtitle = isSchoolWorkForm 
        ? "school-work-form | payment | success"
        : "sick-note | payment | success";
        
    const dialogTitle = isSchoolWorkForm
        ? "Form request submitted successfully"
        : "Sick note request submitted successfully";
        
    const dialogMessage = isSchoolWorkForm
        ? `Your form request has been submitted successfully. Doctor Budai will process your request within 5 business days. The email we have on file is ${user.email}.`
        : `Doctor Budai will get back to you within 24 hours through email. The email we have on file is ${user.email}.`;

    return <>
        <CommonPageHeader title={pageTitle} subtitle={pageSubtitle}/>

        <div className="payment-success" style={{height: '800px'}}>
            <Dialog 
                open={true}
                fullWidth={true}
            >
                <DialogTitle>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    {dialogMessage}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => history.push('/dashboard')}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </>
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(withRouter(PaymentSuccess));